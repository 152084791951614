<template>
    <div>
        <Row class="table-title p-t-2">
            <i-col span="5">所属资产</i-col>
            <i-col span="5">投放方式</i-col>
            <i-col span="5">投放规格</i-col>
            <i-col span="5">投放时长</i-col>
            <i-col span="4">操作</i-col>
        </Row>
        <Row v-for="(resource,index) in list" :key="index" class="p-t-2" :class="computedRowClassName(resource,index)">
            <i-col span="5">{{resource.assetName}}</i-col>
            <i-col span="5">{{resource.productskuName}}</i-col>
            <i-col span="5">{{resource.duration}}秒</i-col>
            <i-col span="5">{{resource.tduration}}秒</i-col>
            <i-col span="4">
              <a v-if="isManage" @click="handleChangeSchedule(resource)">档期</a>
              <a v-if="!customskuId" class="m-l-5 delete" @click="handleDeleteShow(resource)">删除</a>
            </i-col>
        </Row>
        <div class="paging_style">
            <Page size="small" :total="total" :page-size="query.pageSize" show-total show-elevator @on-change="handlePageNumberChange"></Page>
        </div>

        <Modal v-model="showChangeSchedule" width="400" title="资源档期变更" :footer-hide="true">
            <editOrderItemSchedule :params="params" :chooseOrderItemId="chooseOrderItemId" @on-update-order-item="changeSchduleSuccess"/>
        </Modal>
    </div>
</template>

<script>
import editOrderItemSchedule from '../common/EditOrderItemSchedule'

import { toMoney } from '@/utils/wnumb_own'

import { getProductDetailPageByOrder } from '@/api/order/productdetail'
import { deleteOrderItem } from '@/api/order/orderitem'

export default {
  props: {
    params: {
      type: Object
    },
    customskuId: {
      type: Number,
      default: null
    }
  },
  components: {
    editOrderItemSchedule
  },
  data () {
    return {
      isManage: this.$store.state.order.isManage,
      list: [],
      query: { pageNumber: 1, pageSize: 10 },
      total: 0,

      showChangeSchedule: false, // 档期变更
      chooseOrderItemId: 0
    }
  },
  methods: {
    computedRowClassName (row, index) {
      if (row.conflict) {
        return 'table-conflict-row'
      } else if (index % 2 === 0) {
        return 'table-row-1'
      } else {
        return 'table-row-2'
      }
    },
    initPageData (level) {
      this.query.orderId = this.params.orderId
      this.query.productId = this.params.productId
      this.query.startDate = this.params.startDate
      this.query.endDate = this.params.endDate
      this.query.stationLevel = level || null
      getProductDetailPageByOrder(this.query).then(res => {
        this.total = res.totalRow
        this.list = res.list
      })
    },
    handlePageNumberChange (page) {
      this.query.pageNumber = page
      this.initPageData()
    },
    handleDeleteShow (resource) {
      this.$Modal.confirm({
        title: '操作提示',
        content: '点击确定后资源将会从您订单里删除，请谨慎操作',
        onOk: () => {
          this.handleDelete(resource)
        }

      })
    },
    handleDelete (resource) {
      const deleteParam = {
        orderId: this.params.orderId,
        orderitemIds: JSON.stringify([resource.orderitemId])
      }

      deleteOrderItem(deleteParam).then(res => {
        if (res && !res.errcode) {
          this.$Notice.success({ desc: '删除成功' })
          this.$emit('on-update-order', this.params.categoryId)
        }
      })
    },
    handleChangeSchedule (resource) {
      this.chooseOrderItemId = resource.orderitemId
      this.showChangeSchedule = true
    },
    changeSchduleSuccess () {
      this.showChangeSchedule = false
      this.$emit('on-update-order', this.params.categoryId)
    },
    formatMoney (number) {
      return toMoney(number)
    }
  },
  mounted () {
    this.initPageData()
  },
  watch: {
    params: function () {
      this.query.pageNumber = 1
      this.initPageData()
    }
  }
}
</script>
